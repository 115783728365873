

import './App.css';
import { useEffect, useState, useRef } from 'react';
import React from 'react';
import arrow from './assets/arrow.png';
import bg from './assets/bg.jpg';
import { Dna } from 'react-loader-spinner';
import Select from 'react-select';


function App() {
 const [userInput, setUserInput] = useState('');
 const [loading, setLoading] = useState(false);
 const [messages, setMessages] = useState([
   {
     msg: "Hello, How can I help you?",
     category: null,
     fromUser: false,
   }
 ]);


 const bottomRef = useRef(null);
 const textareaRef = useRef(null);


 const themes = {
   primaryColor: "#475569",
   secondryColor: "#475569",
   primaryFontColor: "white",
   secondryFontColor: "#2C3333",
   logoColor: "#E7F6F2",
   backgroudImage: bg
 };


 const [banner, setBanner] = useState('');
 const [botName, setBotName] = useState('');
 const [categories, setCategories] = useState([{ value: '', label: "Select Category" }]);
 const [selectedCategory, setSelectedCategory] = useState("");


 const handleTypeSelect = e => {
   setSelectedCategory(e.value);
 };


 let loaded = false;


 useEffect(() => {
   const urlParams = new URLSearchParams(window.location.search);
   let websiteRef = urlParams.get("websiteRef");
   if (websiteRef != null && !loaded) {
     loaded = true;


     fetch(`${process.env.REACT_APP_SERVER_URL}/api/website/site=${websiteRef}`)
       .then((response) => response.json())
       .then((data) => {
         if (data.banner_url === "") {
           setBanner(bg);
         } else {
           setBanner(data.banner_url);
         }


         setBotName(data.bot_name);
         if (data.categories && data.categories.length > 0 && data.categories.includes(',')) {
           let categoryList = data.categories.split(',');
           let categoryUuidList = data.category_uuid.split(',');
           categoryList.map((obj, index) => (
             setCategories(oldData => [...oldData, { value: categoryUuidList[index], label: obj }])
           ))
         } else if (data.categories && data.categories.length > 0) {
           setCategories(oldData => [...oldData, { value: data.category_uuid, label: data.categories }])
         }
       })
       .catch((err) => {
         console.log(err.message);
       });
   } else if (!loaded) {
     setBanner(themes.backgroudImage);
     setBotName("RevizeBot");
   }
 }, []);


 const decoder = new TextDecoder("utf-8");
 let test = "";


 function urlify(text) {
   var pattern = /(https?:\/\/[^\s]+)/g;


   return text.replace(pattern, function (url) {
     console.log("URL", url);
     return '<a href="' + url + '" target="_blank"><u style="color: blue;">' + url + '</u></a>';
   });
 }


 useEffect(() => {
   if (userInput !== '' && messages[messages.length - 2].fromUser === false) {
     setLoading(true);
     const urlParams = new URLSearchParams(window.location.search);
     let websiteRef = urlParams.get("websiteRef");


     const fetchData = async () => {
       try {
         const response = await Promise.race([
           fetch(`${process.env.REACT_APP_SERVER_URL}/api/chat?websiteRef=${websiteRef}&message=${userInput}&category=${selectedCategory}`),
           new Promise((_, reject) =>
             setTimeout(() => reject(new Error("Timeout")), 20000)
           )
         ]);


         const reader = response.body.getReader();
         reader.read().then(function pump({ done, value }) {
           if (done) {
             return;
           }


           test += decoder.decode(value);
           let message = urlify(test);
           message = message.replace(/^"|"$/g, '').replace(/\n/g, '<br>');
           const formattedResponse = '<p>' + message + '</p>';


           setMessages([...messages, { msg: formattedResponse, category: selectedCategory, fromUser: false }]);
           setLoading(false);


           return reader.read().then(pump);
         });
       } catch (error) {
         setMessages([...messages, { msg: "There is a temporary server downtime.", category: selectedCategory, fromUser: false }]);
         setLoading(false);
       }
     };


     fetchData();
     setUserInput('');
   }


   if (bottomRef.current) {
     bottomRef.current.scrollTop = bottomRef.current.scrollHeight;
   }


 }, [messages]);


 const sendMessage = (event) => {
   event.preventDefault();


   if (loading || userInput === '') {
     return;
   }


   setMessages([...messages, { msg: userInput, category: selectedCategory, fromUser: true }]);
 };


 const handleKeyPress = (event) => {
   if (event.key === 'Enter' && event.shiftKey) {
     event.preventDefault();
     setUserInput((previous) => previous + "\n");
     adjustTextareaHeight();
   } else if (event.key === 'Enter') {
     event.preventDefault();
     sendMessage(event);
   } else if (event.key === 'Backspace') {
     adjustTextareaHeight();
   }
 };


 const [divHeight,setDivHeight] = useState(8)
 const adjustTextareaHeight = () => {
   const textarea = textareaRef.current;
   const maxHeight = 70;
   textarea.style.height = "auto";
  
   if (textarea.scrollHeight <= maxHeight) {
     textarea.style.height = `${textarea.scrollHeight}px`;
     textarea.style.overflowY = "hidden";
     setDivHeight(8)
   } else {
     textarea.style.height = `${maxHeight}px`;
     textarea.style.overflowY = "auto";
     setDivHeight(10)
   }


   // if (textarea.scrollHeight > textarea.clientHeight) {
   //   textarea.style.marginBottom = "5px";
   // } else {
   //   textarea.style.marginBottom = "0";
   // }
 };


 useEffect(() => {
   adjustTextareaHeight();
 }, [userInput]);


 return (
   <div className="min-h-screen bg-gray-100" style={{ backgroundImage: `url(${banner})`, backgroundSize: "cover" }}>
     <div style={{ backgroundColor: themes.primaryColor }} className="w-full h-18 fixed flex justify-between">
       <div style={{ color: themes.logoColor }} className='text-green-100 text-3xl font-bold p-5 font-sans'>{botName}</div>
     </div>


     <div className='pt-20 sm:w-full md:w-1/2 lg:w-1/2 md:m-auto lg:m-auto'>
       {loading && (
         <div
           className='flex justify-center items-center w-full sm:w-full md:w-1/2 lg:w-1/2'
           style={{
             position: "absolute",
             height: "100%",
             pointerEvents: "none"
           }}
         >
           <Dna
             visible={true}
             height="100"
             width="100"
             ariaLabel="dna-loading"
             wrapperStyle={{}}
             wrapperClass="dna-wrapper"
           />
         </div>
       )}


       {categories.length > 1 ? (
         <Select
           options={categories}
           onChange={handleTypeSelect}
           value={categories.filter(function (category) {
             return category.value === selectedCategory;
           })}
           placeholder="Select Category"
         />
       ) : ''}


       <ul className="px-5 overflow-scroll" style={{ "whiteSpace": "pre-wrap", "maxHeight": "330px", "overflowX": "hidden", "paddingBottom": "85px" }} ref={bottomRef}>
         {messages && messages.map((message, idx) => (
           <div key={idx} className={`mt-3 ${message.fromUser ? "place-self-end text-right" : "place-self-start text-left"}`}>
             <div className="mt-3 p-3 rounded-2xl"
               style={{
                 backgroundColor: message.fromUser ? themes.primaryColor : 'white',
                 color: message.fromUser ? themes.primaryFontColor : themes.secondryFontColor,
                 borderTopLeftRadius: !message.fromUser && 0,
                 borderTopRightRadius: message.fromUser && 0
               }}
             >
               <div className="break-words text-md" dangerouslySetInnerHTML={{ __html: message.msg }} />
             </div>
           </div>
         ))}
       </ul>
     </div>


     <div className={`w-full justify-center sm:m-0 md:m-auto lg:m-auto items-center bg-white rounded-xl flex fixed bottom-10`}>
 <form
   className='w-full md:w-1/2 lg:w-1/2 px-6 fixed flex flex-col justify-center'
   onSubmit={sendMessage}
   onKeyDown={handleKeyPress}
   style={{ alignItems: 'flex-end' }}
 >
   <div className="flex w-full">
     <textarea
       ref={textareaRef}
       className='p-3 bg-white w-full rounded-l-md border-0 outline-none min-h-12'
       placeholder="Ask your question..."
       id="message"
       name="message"
       value={userInput}
       onChange={(e) => {
         setUserInput(e.target.value);
         adjustTextareaHeight();
       }}
       style={{ resize: "none", height: "auto", border: '3px solid #475569', overflowY: "hidden", flexGrow: 1, minHeight : "48px", lineHeight : "1"}}
       rows={1}
       maxLength="1000"
     />
     <button
       type="submit"
       style={{
         backgroundColor: themes.secondryColor,
         opacity: loading ? 0.5 : 1,
         cursor: loading ? 'not-allowed' : 'pointer',
         alignSelf: 'flex-end' // Ensure button aligns at the bottom
       }}
       className="flex justify-center items-center p-4 rounded-r-xl max-h-12 flex-end"
       disabled={loading}
     >
       <img className='w-8' src={arrow} alt="arrow" />
     </button>
   </div>
 </form>
</div>


   </div>
 );
}


export default App;

